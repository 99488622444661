import React from "react";
import { currencyWithDecimals } from "../currency";

export function calculateCHPLDiscount(lineItems) {
  let individualCHPLCost = 0;
  let CHPLSqFtTotal = 0;
  let chplQuantity = 0;

  lineItems.forEach((lineItem) => {
    if (lineItem.chplSqFt) {
      individualCHPLCost +=
        calculate_chpl_cost(lineItem.chplSqFt, 1) * lineItem.quantity;
      CHPLSqFtTotal += lineItem.chplSqFt * lineItem.quantity;
      chplQuantity += lineItem.quantity;
    }
  });

  let bulkCHPLCost = 0;
  if (chplQuantity != 0) {
    bulkCHPLCost = calculate_chpl_cost(CHPLSqFtTotal, chplQuantity);
  }

  return individualCHPLCost - bulkCHPLCost;
}

export default class CHPL extends React.Component {
  render() {
    const { chplDiscount } = this.props;
    if (chplDiscount <= 0) {
      return null;
    }
    return (
      <div className="grid grid-cols-12">
        <div className="col-span-8 sm:col-start-2">
          <p className="self-end font-bold text-red-600 border-b border-black">
            CHPL Graphics Bulk Discount
          </p>
        </div>
        <div className="col-span-4 sm:col-span-3">
          <p className="font-bold text-right text-red-600 border-b border-black">
            {currencyWithDecimals(chplDiscount * -1)}
          </p>
        </div>
        <div className="col-span-7 sm:col-start-2">
          <p className="mt-2 mb-4 text-sm italic text-gray-600">
            You saved {currencyWithDecimals(chplDiscount)} on CHPL graphics by
            ordering multiple.
          </p>
        </div>
      </div>
    );
  }
}
