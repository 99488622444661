import React from "react";
import FormEmpty from "./form_empty";
import ContactInformationForm from "./contact_information_form";
import DeliveryForm from "./delivery_form";
import LineItem from "./line_item";
import { currencyWithDecimals } from "../currency";
import LeadTime from "./lead_time";
import { calculateLeadTime } from "./lead_time";
import CHPL from "./chpl";
import { calculateCHPLDiscount } from "./chpl";
import Shipping from "./shipping";
import { shippingInfoFromLineItems } from "./shipping";
import NetlifyFields from "./netlify";

export default class Form extends React.Component {
  render() {
    const {
      lineItems,
      contact_fields,
      onAddonsChange,
      onQuantityChange,
      onNotesChange,
      onContactFieldChange,
    } = this.props;

    if (Object.keys(lineItems).length < 1) {
      return <FormEmpty />;
    }

    const shippingInfo = shippingInfoFromLineItems(
      lineItems,
      contact_fields.miles,
    );
    const { shippingTotal, deliveryTotal, shippingType } = shippingInfo;
    const leadTimeWeeks = calculateLeadTime(lineItems);
    const chplDiscount = calculateCHPLDiscount(lineItems);

    const subtotal = this.props.lineItems.reduce((acc, lineItem) => acc + lineItem.subtotal(), 0);

    const total = subtotal + shippingTotal + deliveryTotal - chplDiscount;

    return (
      <form name="quote-request" method="POST" action="/quote/thank-you/">
        {/* To connect to Netlify form */}
        <NetlifyFields
          subject={`Upland Exhibits Quote Request [#%{submissionId}] - ${contact_fields.organization_name}`}
          lineItems={lineItems}
          leadTimeWeeks={leadTimeWeeks}
          shippingInfo={shippingInfo}
        />

        <div className="hidden grid-cols-12 gap-0 mb-4 sm:grid print:grid print:text-sm">
          <div className="col-span-7 font-bold border-b-2 border-black">
            Product
          </div>
          <div className="col-span-1 font-bold text-right border-b-2 border-black">
            Price
          </div>
          <div className="col-span-2 font-bold text-center border-b-2 border-black">
            Quantity
          </div>
          <div className="col-span-2 font-bold text-right border-b-2 border-black">
            Subtotal
          </div>
        </div>

        {lineItems.map((lineItem) => (
          <LineItem
            key={lineItem.productID}
            lineItem={lineItem}
            addonChangeHandler={onAddonsChange}
            quantityChangeHandler={onQuantityChange}
            notesChangeHandler={onNotesChange}
          />
        ))}

        <div className="mt-8 space-y-8">
          <CHPL chplDiscount={chplDiscount} />
          <LeadTime leadTimeWeeks={leadTimeWeeks} />

          {shippingType === "delivery" ? (
            <DeliveryForm
              miles={contact_fields.miles}
              price={deliveryTotal}
              milesChangeHandler={onContactFieldChange}
            />
          ) : (
            <Shipping total={shippingTotal} />
          )}
        </div>

        <div className="grid grid-cols-12 mt-12">
          <div className="col-span-8 sm:col-start-2">
            <p className="self-end font-bold border-b-2 border-black">Total</p>
          </div>
          <div className="col-span-4 sm:col-span-3">
            <p className="font-bold text-right border-b-2 border-black">
              {currencyWithDecimals(total)}
            </p>
          </div>
        </div>

        <div className="pt-8 mt-12 border-t border-gray-400 border-dotted sm:ml-16 print:mx-8 print:border-0 print:py-0">
          <ContactInformationForm
            contact_fields={contact_fields}
            changeHandler={onContactFieldChange}
          />
        </div>

        <button
          type="submit"
          className="block px-4 py-2 mx-auto mt-8 mb-12 font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 print:hidden"
        >
          Submit
        </button>
      </form>
    );
  }
}
