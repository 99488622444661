import React from "react";
import { currencyWithDecimals } from "../currency";

export default class PedestalBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pedestalWidth: 14,
      pedestalDepth: 14,
      pedestalHeight: 36,
      vitrineHeight: 14,
      style: "Groove",
      includeVitrine: false,
    };
  }
  priceFromDimensions(width, depth, height) {
    return Math.ceil(
      (width * height * 2 + depth * height * 2 + width * depth) / 3 + 200,
    );
  }

  vitrinePriceFromDimensions(width, depth, height) {
    return Math.ceil(
      (width * height * 2 + depth * height * 2 + width * depth) / 3 + 500,
    );
  }

  calculatePedestalWeight(width, depth, height) {
    // add the sides, double the top since it is 3/4" thick plus a sub-top frame
    var balticBirchWeightPerSqInch12mm = 48 / 4608; // 48lbs per 4x8 sheet
    var pedestalWeight =
      (width * height * 2 + depth * height * 2 + width * depth * 2) *
      balticBirchWeightPerSqInch12mm;
    return Math.round(pedestalWeight);
  }
  calculateVitrineWeight(width, depth, height, vitrineHeight) {
    var acrylicWeightPerSqInch6mm = 1.424 / 144; //1.424lbs per sq ft
    var vitrineWeight = 0;
    if (vitrineHeight == 0) {
      vitrineWeight = 0;
    } else {
      vitrineWeight =
        ((width * vitrineHeight + 1) * 2 +
          (depth * vitrineHeight + 1) * 2 +
          width * depth) *
        acrylicWeightPerSqInch6mm; // use full width even though Groove pedestals are slightly smaller
    }
    return Math.round(vitrineWeight);
  }
  render() {
    const {
      pedestalWidth,
      pedestalDepth,
      pedestalHeight,
      vitrineHeight,
      style,
      includeVitrine,
    } = this.state;

    let pedestalPrice = this.priceFromDimensions(
      pedestalWidth,
      pedestalDepth,
      pedestalHeight,
    );

    let totalPrice = pedestalPrice;

    let vitrineWidth,
      vitrineDepth,
      vitrineInteriorWidth,
      vitrineInteriorDepth,
      vitrineInteriorHeight,
      vitrineDimensions,
      vitrineChoice;

    if (style === "Groove") {
      vitrineWidth = pedestalWidth - 1;
      vitrineDepth = pedestalDepth - 1;
      vitrineInteriorWidth = vitrineWidth - 0.5;
      vitrineInteriorDepth = vitrineDepth - 0.5;
      vitrineInteriorHeight = vitrineHeight - 1;
    } else if (style === "Step") {
      vitrineWidth = pedestalWidth;
      vitrineDepth = pedestalDepth;
      vitrineInteriorWidth = vitrineWidth - 0.5;
      vitrineInteriorDepth = vitrineDepth - 0.5;
      vitrineInteriorHeight = vitrineHeight - 1;
    }

    let pedestalWeight = this.calculatePedestalWeight(
      pedestalWidth,
      pedestalDepth,
      pedestalHeight,
    );
    let vitrineWeight = this.calculateVitrineWeight(
      pedestalWidth,
      pedestalDepth,
      pedestalHeight,
      vitrineHeight,
    );

    if (style !== "Flat") {
      vitrineChoice = (
        <div className="mt-6">
          <h4 className="relative mb-2 font-bold border-b">Vitrine</h4>

          <div className="mb-2">
            <label htmlFor="includeVitrine" className="block text-gray-700">
              Include acrylic vitrine
            </label>
            <select
              id="includeVitrine"
              name="includeVitrine"
              value={includeVitrine ? "Yes" : "No"}
              onChange={(event) =>
                this.setState({
                  includeVitrine: event.target.value === "Yes",
                })
              }
              className="block py-2 pl-3 pr-10 border-gray-600 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
      );
    }

    if (includeVitrine && style !== "Flat") {
      totalPrice += this.vitrinePriceFromDimensions(
        vitrineWidth,
        vitrineDepth,
        vitrineHeight,
      );

      vitrineDimensions = (
        <div>
          <h5 className="pb-1 mt-6 mb-1 font-semibold border-b border-gray-200 border-dotted">
            Exterior vitrine dimensions
          </h5>
          <div className="flex sm:w-1/2">
            <div className="mr-1">
              <label htmlFor="vitrineWidth" className="text-gray-600">
                Width
                <br />
                <input
                  name="vitrineWidth"
                  className="w-20 text-gray-400 border-gray-200"
                  type="number"
                  value={vitrineWidth}
                  disabled
                />
              </label>
            </div>
            <div className="mr-1">
              <label htmlFor="vitrineDepth" className="mr-1 text-gray-600">
                Depth
                <br />
                <input
                  name="vitrineDepth"
                  className="w-20 text-gray-400 border-gray-200"
                  type="number"
                  value={vitrineDepth}
                  disabled
                />
              </label>
            </div>
            <div className="mr-3">
              <label htmlFor="vitrineHeight" className="mr-1 text-gray-600">
                Height
                <br />
                <input
                  name="vitrineHeight"
                  className="w-20 border-gray-600"
                  type="number"
                  step="1"
                  value={vitrineHeight}
                  onChange={(event) =>
                    this.setState({ vitrineHeight: event.target.value })
                  }
                  min="2"
                  max="36"
                />
              </label>
            </div>
          </div>

          <h5 className="pb-1 mt-6 mb-1 font-semibold border-b border-gray-200 border-dotted">
            Interior vitrine clearance
          </h5>
          <div className="flex sm:w-1/2">
            <div className="mr-1">
              <label htmlFor="vitrineInteriorWidth" className="text-gray-600">
                Width
                <br />
                <input
                  name="vitrineInteriorWidth"
                  className="w-20 text-gray-400 border-gray-200"
                  type="number"
                  value={vitrineInteriorWidth}
                  disabled
                />
              </label>
            </div>
            <div className="mr-1">
              <label
                htmlFor="vitrineInteriorDepth"
                className="mr-1 text-gray-600"
              >
                Depth
                <br />
                <input
                  name="vitrineInteriorDepth"
                  className="w-20 text-gray-400 border-gray-200"
                  type="number"
                  value={vitrineInteriorDepth}
                  disabled
                />
              </label>
            </div>
            <div className="mr-3">
              <label
                htmlFor="vitrineInteriorHeight"
                className="mr-1 text-gray-600"
              >
                Height
                <br />
                <input
                  name="vitrineInteriorHeight"
                  className="w-20 text-gray-400 border-gray-200"
                  type="number"
                  value={vitrineInteriorHeight}
                  disabled
                />
              </label>
            </div>
          </div>
          <p className="relative">
            <span className="absolute right-0 inline-block text-xs text-gray-500 align-baseline">
              {vitrineWeight} lbs
            </span>
          </p>
        </div>
      );
    }

    return (
      <div className="max-w-3xl p-4 py-6 mx-auto bg-indigo-700 pedestal-builder sm:rounded-lg sm:shadow-lg">
        <div className="items-center sm:flex">
          <div className="mb-4 sm:w-1/2 sm:pr-20 sm:pl-10">
            <img
              src="/assets/img/Upland-logo-white.svg"
              alt=""
              className="hidden mb-6 sm:block"
            />
            <h2 className="mb-4 text-4xl font-bold leading-tight text-white sm:text-5xl sm:mb-8">
              Pedestal Builder
            </h2>
            <p className="text-indigo-300">pedestal cost</p>
            <p className="pt-4 text-2xl font-semibold text-white border-t border-indigo-300">
              {currencyWithDecimals(totalPrice)}
            </p>
          </div>
          <form
            className="p-4 bg-white rounded shadow sm:w-1/2"
            onSubmit={(e) => {
              e.preventDefault();

              const pedestalText = `${pedestalWidth}" x ${pedestalDepth}" x ${pedestalHeight}"`;
              let vitrineText = "";
              let shipping = 240;
              if (includeVitrine && style !== "Flat") {
                vitrineText = ` with ${vitrineWidth}" x ${vitrineDepth}" x ${vitrineHeight}" vitrine`;
                shipping = 360;
              }
              const img = {
                Step: "/assets/img/products/pedestals/pedestal_14x14x40_charcoal.jpg",
                Step_vitrine:
                  "/assets/img/products/pedestals/pedestal_14x14x40_vitrine_charcoal.jpg",
                Groove:
                  "/assets/img/products/pedestals/pedestal_14x14x40_charcoal.jpg",
                Groove_vitrine:
                  "/assets/img/products/pedestals/pedestal_14x14x40_vitrine_charcoal.jpg",
                // Flat and Flat_vitrine should be the same
                Flat: "/assets/img/products/pedestals/groove-pedestal-gray.png",
                Flat_vitrine:
                  "/assets/img/products/pedestals/groove-pedestal-gray.png",
              }[`${style}${includeVitrine ? "_vitrine" : ""}`];

              this.props.addToQuote(
                `${style} Pedestal ${pedestalText} ${vitrineText}`,
                {
                  title: `${style} Pedestal`,
                  description: `${pedestalText}${vitrineText}`,
                  price: totalPrice,
                  img: img,
                  shipping: shipping,
                  leadTimeWeeks: totalWeeks,
                },
              );
              window.location = "/quote/";
            }}
          >
            <h4 className="relative mb-2 font-bold border-b">Pedestal</h4>
            <div className="flex w-1/2">
              <div className="mr-1">
                <label htmlFor="width" className="text-gray-700">
                  Width
                  <br />
                  <input
                    id="width"
                    className="w-16 border-gray-600"
                    type="number"
                    step="1"
                    value={pedestalWidth}
                    onChange={(event) =>
                      this.setState({ pedestalWidth: event.target.value })
                    }
                    min="10"
                    max="46"
                  />
                </label>
              </div>
              <div className="mr-1">
                <label htmlFor="pedestalDepth" className="mr-1 text-gray-700">
                  Depth
                  <br />
                  <input
                    id="pedestalDepth"
                    className="w-16 border-gray-600"
                    type="number"
                    step="1"
                    value={pedestalDepth}
                    onChange={(event) =>
                      this.setState({ pedestalDepth: event.target.value })
                    }
                    min="10"
                    max="46"
                  />
                </label>
              </div>
              <div className="mr-3">
                <label htmlFor="pedestalHeight" className="mr-1 text-gray-700">
                  Height
                  <br />
                  <input
                    id="pedestalHeight"
                    className="w-16 border-gray-600"
                    type="number"
                    step="1"
                    value={pedestalHeight}
                    onChange={(event) =>
                      this.setState({ pedestalHeight: event.target.value })
                    }
                    min="10"
                    max="46"
                  />
                </label>
              </div>
            </div>
            <div className="mt-2">
              <label htmlFor="style" className="block text-gray-700">
                Style
              </label>
              <select
                id="style"
                name="style"
                value={style}
                onChange={(event) =>
                  this.setState({ style: event.target.value })
                }
                className="block py-2 pl-3 pr-10 border-gray-600 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option>Step</option>
                <option>Groove</option>
                <option>Flat</option>
              </select>
            </div>
            <p className="relative">
              <span className="absolute right-0 inline-block text-xs text-gray-500 align-baseline">
                {pedestalWeight} lbs
              </span>
            </p>

            {vitrineChoice}
            {vitrineDimensions}

            <button type="submit" className="mt-6 mb-2 btn">
              Add to Quote
            </button>
          </form>
        </div>
      </div>
    );
  }
}
