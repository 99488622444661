import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import Products from "./products.json";
import { currencyWithoutDecimals } from "./currency";
import App from "./app";
import renderMatfieldTableCases from "./three/matfield_table_cases";
import renderMatfieldDisplayCases from "./three/matfield_display_cases";
import renderNestingPedestals from "./three/nesting_pedestals";
import renderZigZags from "./three/zig-zags";
import renderRidgeline from "./three/ridgeline";

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
  });
}

// Render three.js based on data-three="{name}" elements
document.querySelectorAll("[data-three]").forEach(function (element) {
  const name = element.getAttribute("data-three");
  // threeRenderers[name](element);
  if (name == "matfield-table-cases") {
    renderMatfieldTableCases(element);
  } else if (name == "matfield-display-cases") {
    renderMatfieldDisplayCases(element);
  } else if (name == "nesting-pedestals") {
    renderNestingPedestals(element);
  } else if (name == "zig-zags") {
    renderZigZags(element);
  } else if (name == "ridgeline") {
    renderRidgeline(element);
  }
});

window.addEventListener("DOMContentLoaded", (event) => {
  const app = ReactDOM.render(
    <App
      // Renders specific per-page elements through portals
      quoteFormElement={document.getElementById("react-quote-form")}
      pedestalBuilderElement={document.getElementById("react-pedestal-builder")}
    />,
    // But directly renders the quote info in the top-right corner of every page
    document.getElementById("react-quote-mini"),
  );

  // Make available to regular JS on other pages
  window.addToQuote = app.addToQuote;

  // Hook up regular HTML buttons to add items to quote
  // <button data-quote="product_id_in_products_json">
  document.querySelectorAll("[data-quote]").forEach(function (button) {
    button.addEventListener("click", function (event) {
      event.preventDefault();
      app.addToQuote(this.getAttribute("data-quote"));
      window.location = "/quote/";
    });
  });

  document.querySelectorAll("[data-price]").forEach(function (element) {
    const productID = element.getAttribute("data-price");
    element.innerHTML = currencyWithoutDecimals(Products[productID].price);
  });
});
