import React from "react";
import { currencyWithDecimals } from "../currency";

export function shippingInfoFromLineItems(lineItems, miles) {
  let shippingType = "ground"; // Assume ground to start with
  let shippingTotal = 0;
  let deliveryTotal = 0;

  lineItems.forEach((lineItem) => {
    if (
      lineItem.delivery &&
      (shippingType === "ground" || shippingType === "freight")
    ) {
      shippingType = "delivery";
    }
    if (shippingType === "ground" && lineItem.shippingType === "freight") {
      shippingType = "freight";
    }
    shippingTotal += (lineItem.shipping || 0) * lineItem.quantity;
  });

  if (shippingType === "delivery") deliveryTotal = (miles || 0) * 3.5 + 50;

  // to the api...
  // - line items
  //   - id
  //   - qty
  //   - title
  // - shipping type

  return { shippingTotal, deliveryTotal, shippingType };
}

export default class Shipping extends React.Component {
  render() {
    const { total } = this.props;
    return (
      <div className="grid grid-cols-12">
        <div className="col-span-8 sm:col-start-2">
          <p className="self-end font-bold border-b border-black">Shipping</p>
        </div>
        <div className="col-span-4 sm:col-span-3">
          <p className="font-bold text-right border-b border-black">
            {currencyWithDecimals(total)}
          </p>
        </div>
        <div className="col-span-7 sm:col-start-2">
          <p className="mt-2 mb-4 text-sm italic text-gray-600">
            Shipping has been estimated for your quote.
          </p>
        </div>
      </div>
    );
  }
}
