import React from "react";

export function calculateLeadTime(lineItems) {
  // Find the longest lead time of all line items
  let leadTimeWeeks = 0;

  lineItems.forEach((lineItem) => {
    leadTimeWeeks = Math.max(leadTimeWeeks, lineItem.leadTimeWeeks || 0);
  });

  return leadTimeWeeks;
}

export default class LeadTime extends React.Component {
  render() {
    const { leadTimeWeeks } = this.props;
    if (leadTimeWeeks <= 0) {
      return null;
    }
    return (
      <div className="grid grid-cols-12">
        <div className="col-span-8 sm:col-start-2">
          <p className="self-end font-bold border-b border-black">Lead Time</p>
        </div>
        <div className="col-span-4 sm:col-span-3">
          <p className="font-bold text-right border-b border-black">
            {leadTimeWeeks} week{leadTimeWeeks > 1 ? "s" : ""}
          </p>
        </div>
        <div className="col-span-7 sm:col-start-2">
          <p className="mt-2 mb-4 text-sm italic text-gray-600">
            Estimate based on the longest lead time of all items in your quote.
          </p>
        </div>
      </div>
    );
  }
}
