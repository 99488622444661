import React from "react";

export default class NetlifyFields extends React.Component {
  lineItemsToFormText(lineItems, shippingInfo, leadTimeWeeks) {
    let textLineItems = "";

    lineItems.forEach((lineItem) => {
      textLineItems += `${lineItem.singleFieldDescription()}\n  Price: $${
        lineItem.price
      }, Quantity: ${lineItem.quantity}\n\n`;

      // We should only have "true" addons at this point
      Object.keys(lineItem.addonsEnabled || {}).forEach((addonID) => {
        const addon = lineItem.standardProduct.addons[addonID];
        textLineItems += `  Add-on: ${addon.title}\n    Price: $${addon.price}, Quantity: ${lineItem.quantity}\n\n`;
      });
    });

    const { shippingTotal, deliveryTotal, deliveryRequired } = shippingInfo;
    if (deliveryRequired) {
      textLineItems += `Estimated Delivery: $${deliveryTotal}\n`;
    } else {
      textLineItems += `Estimated Shipping: $${shippingTotal}\n`;
    }

    if (leadTimeWeeks > 0) {
      textLineItems += `Estimated Lead Time: ${leadTimeWeeks} week${
        leadTimeWeeks > 1 ? "s" : ""
      }\n`;
    }

    return textLineItems;
  }
  render() {
    const { lineItems, shippingInfo, leadTimeWeeks, subject } = this.props;
    const lineItemsText = this.lineItemsToFormText(
      lineItems,
      shippingInfo,
      leadTimeWeeks,
    );
    return (
      <React.Fragment>
        <input
          type="hidden"
          name="subject"
          data-remove-prefix
          value={subject}
        />
        <input type="hidden" name="form-name" value="quote-request" />
        <input type="hidden" name="line_items" value={lineItemsText} />
      </React.Fragment>
    );
  }
}
