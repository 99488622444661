import { doc } from 'prettier';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { add } from 'three/examples/jsm/nodes/Nodes.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
// import obj loader
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
// import gltf loader
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { clone } from 'three/examples/jsm/utils/SkeletonUtils.js';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';

export default function render(element) {
    // Get size of container
    let renderContainer = document.getElementById('render-container').getBoundingClientRect();

    // Set up scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, renderContainer.width / renderContainer.width, 0.1, 1000);

    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(renderContainer.width, renderContainer.width); // Square aspect ratio based on width
    renderer.setClearColor(0x1f1e1c, 0);
    renderer.toneMapping = THREE.ACESFilmicToneMapping; // Choose a tone mapping method
    renderer.toneMappingExposure = 1; // Adjust exposure
    // renderer.physicallyCorrectLights = true; // Enable physical lighting
    // renderer.outputEncoding = THREE.sRGBEncoding;
    // Enable shadow rendering
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Optional: for softer shadows

    element.appendChild(renderer.domElement);

    const canvas = renderer.domElement;

    // Add Orbit Controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;

    // Set the point around which the camera orbits
    const target = new THREE.Vector3(0, 24, 0); // Example target
    controls.target.copy(target);
    controls.update();

    // Prevent camera from going below ground
    const degrees = 80;
    const radians = degrees * Math.PI / 180;
    controls.maxPolarAngle = radians;

    // Add HDRI environment map
    const hdrEquirect = new RGBELoader().load(
        "/assets/img/threejs/hdr/rostock_laage_airport_1k.hdr",
        () => {
            hdrEquirect.mapping = THREE.EquirectangularReflectionMapping;
        }
    );

    // Create wood-like material
    const woodTexture = new THREE.TextureLoader().load('/assets/img/threejs/materials/cherry.jpg');
    const woodMaterial = new THREE.MeshPhysicalMaterial({
        map: woodTexture,
        clearcoat: 1,
    });
    woodTexture.colorSpace = THREE.SRGBColorSpace;

    const paintMaterial = new THREE.MeshPhysicalMaterial({ color: '#606E7B' });

    // make a black powder coat metal material
    const blackMetalMaterial = new THREE.MeshPhysicalMaterial({
        color: 0x222222,
        metalness: 1,
        roughness: 0.1,
    });

    // Create glass / acrylic material
    const glassMaterial = new THREE.MeshPhysicalMaterial({
        color: 0xa8ccd7,
        metalness: 0,
        roughness: 0.1,
        transmission: .9, // transparency
        opacity: 0.3,
        transparent: true,
        reflectivity: 0.9,
        clearcoat: 1,
        clearcoatRoughness: 0.1,
        envMap: hdrEquirect,
    });


    // Enable shadow casting
    //graphicPanel1.castShadow = true;






    // Add lighting ----------------------------------------------
    const light = new THREE.DirectionalLight(0xffffff, 3);
    light.position.set(30, 150, 40);
    // Enable shadow casting for light
    light.castShadow = true;
    // Adjust shadow camera frustum for better shadow quality
    light.shadow.camera.near = 1;
    light.shadow.camera.far = 200;
    light.shadow.camera.left = -200;
    light.shadow.camera.right = 200;
    light.shadow.camera.top = 200;
    light.shadow.camera.bottom = -200;
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    scene.add(light);

    // Create a target for the directional light
    const lightTarget = new THREE.Object3D();
    lightTarget.position.set(0, 60, 0); // Set the target position
    scene.add(lightTarget);

    // Set the target of the directional light
    light.target = lightTarget;

    // Add light helpers
    // const directionalLightHelper = new THREE.DirectionalLightHelper(light, 3);
    // scene.add(directionalLightHelper);

    // Add ambient light to soften shadows
    const ambientLight = new THREE.AmbientLight(0xffffff, 4);
    scene.add(ambientLight);

    // const pointLight = new THREE.PointLight(0xffffff, 2);
    // pointLight.position.set(-80, 90, 30);
    // scene.add(pointLight);

    // // Add point light helper
    // const pointLightHelper = new THREE.PointLightHelper(pointLight, 3);
    // scene.add(pointLightHelper);

    const axesHelper = new THREE.AxesHelper(40);
    scene.add(axesHelper);
    // Red: X-axis
    // Green: Y-axis
    // Blue: Z-axis

    // Add a ground plane to receive shadows
    const groundGeometry = new THREE.PlaneGeometry(400, 400);
    const groundMaterial = new THREE.MeshPhysicalMaterial({ color: 0xdddddd });
    const ground = new THREE.Mesh(groundGeometry, groundMaterial);
    ground.rotation.x = -Math.PI / 2;
    ground.position.y = -0.01; // Slightly below the pedestals
    ground.receiveShadow = true;
    scene.add(ground);


    // Position camera
    camera.position.set(0, 200, 60); // looks nice for this scene
    controls.update();

    // Render loop
    function animate() {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
    }
    animate();

    // Handle window resizing
    window.addEventListener('resize', onWindowResize, false);

    function onWindowResize() {
        renderContainer = document.getElementById('render-container').getBoundingClientRect();
        camera.aspect = renderContainer.width / renderContainer.width;
        camera.updateProjectionMatrix();
        renderer.setSize(renderContainer.width, renderContainer.width);
    }

    function createAxesHelper(size = 15) {
        return new THREE.AxesHelper(size);
    }

/*

    let railTops = []; // Array to store references to rail meshes

    // Function to create the rail top trapezoid with a group structure
    function createTrapezoidRailTop(railWidth, depth, leftEndAngle, rightEndAngle, material) {
        const group = new THREE.Group();

        // Create trapezoid geometry
        const geometry = createTrapezoidGeometry(railWidth, depth, leftEndAngle, rightEndAngle);
        const trapezoidMesh = new THREE.Mesh(geometry, material);
        group.add(trapezoidMesh);

        // Add rail width line
        const railWidthLineMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 }); // Red
        const railWidthLineGeometry = new THREE.BufferGeometry().setFromPoints([
            new THREE.Vector3(-railWidth / 2, 0.01, depth / 2), // Top-left
            new THREE.Vector3(railWidth / 2, 0.01, depth / 2),  // Top-right
        ]);
        const railWidthLine = new THREE.Line(railWidthLineGeometry, railWidthLineMaterial);
        group.add(railWidthLine);

        // Add axes helper
        const axesHelper = createAxesHelper(5);
        group.add(axesHelper);

        return group;
    }

    // Function to create the trapezoid geometry
    function createTrapezoidGeometry(railWidth, depth, leftEndAngle, rightEndAngle) {
        // Convert angles to radians
        const leftAngleRad = (leftEndAngle * Math.PI) / 180;
        const rightAngleRad = (rightEndAngle * Math.PI) / 180;

        // Define vertices for the trapezoid
        // This isn't necessarily an isosceles trapezoid
        // Because the angles can be different on each side
        // So we have to use trigonometry to calculate each half separately
        const vertices = new Float32Array([
            // Front face (closer to the viewer, with sloped edges)
            -railWidth / 2, 0.1, -depth / 2, // Top-left
            railWidth / 2, 0.1, -depth / 2, // Top-right
            -railWidth / 2 + Math.tan(leftAngleRad) * depth, 0, depth / 2, // Bottom-left
            railWidth / 2 - Math.tan(rightAngleRad) * depth, 0, depth / 2, // Bottom-right
        ]);

        // Define the indices for the faces
        const indices = [
            0, 1, 2, // Top-left to top-right to base-left
            1, 3, 2, // Top-right to base-right to base-left
        ];

        // Create BufferGeometry and assign attributes
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
        geometry.setIndex(indices);
        geometry.computeVertexNormals(); // Ensure proper lighting

        return geometry;
    }

    // Function to create an origin marker (a small sphere)
    function createOriginMarker(size = 0.5, color = 0x00ff00) {
        const geometry = new THREE.SphereGeometry(size, 16, 16);
        const material = new THREE.MeshStandardMaterial({ color });
        return new THREE.Mesh(geometry, material);
    }

    // Main function to build and update the rail system
    function ridgelineBuilder() {
        // Shared Characteristics
        const graphicsDepth = parseFloat(document.getElementById('graphicsDepth').value);
        const numRails = parseInt(document.getElementById('numRails').value, 10);

        // Ensure `railTops` array matches the number of rails
        while (railTops.length < numRails) {
            const material = new THREE.MeshStandardMaterial({ color: 0x00aaff, side: THREE.DoubleSide });
            const newRail = createTrapezoidRailTop(1, 1, 0, 0, material); // Placeholder dimensions
            railTops.push(newRail);
            scene.add(newRail);
        }

        while (railTops.length > numRails) {
            const removedRail = railTops.pop();

            // Properly dispose of geometry and material for the trapezoid mesh
            const trapezoidMesh = removedRail.children.find(child => child.isMesh);
            if (trapezoidMesh) {
                if (trapezoidMesh.geometry) trapezoidMesh.geometry.dispose();
                if (trapezoidMesh.material) trapezoidMesh.material.dispose();
            }

            scene.remove(removedRail);
        }

        // Positioning logic
        let currentAngle = 0; // Current cumulative rotation angle in degrees
        let nextCenterX = 0; // Current X position
        let nextCenterZ = 0; // Current Z position

        for (let i = 0; i < numRails; i++) {
            const railWidth = parseFloat(document.getElementById(`railWidth${i + 1}`).value);
            const leftEndAngle = parseFloat(document.getElementById(`leftEndAngle${i + 1}`).value);
            const rightEndAngle = parseFloat(document.getElementById(`rightEndAngle${i + 1}`).value);

            // Find the trapezoid mesh in the group
            const trapezoidMesh = railTops[i].children.find(child => child.isMesh);

            if (trapezoidMesh) {
                // Update the geometry for the trapezoid mesh
                const updatedGeometry = createTrapezoidGeometry(railWidth, graphicsDepth, leftEndAngle, rightEndAngle);

                // Update the position of the rail width line
                const railWidthLine = railTops[i].children.find(child => child.isLine);
                if (railWidthLine) {
                    railWidthLine.geometry = new THREE.BufferGeometry().setFromPoints([
                        new THREE.Vector3(-railWidth / 2, 0.01, -graphicsDepth / 2), // Top-left
                        new THREE.Vector3(railWidth / 2, 0.01, -graphicsDepth / 2),  // Top-right
                    ]);
                }

                // Dispose of the old geometry
                if (trapezoidMesh.geometry) trapezoidMesh.geometry.dispose();

                // Assign the updated geometry
                trapezoidMesh.geometry = updatedGeometry;
            }

            // Position the rail
            railTops[i].position.set(nextCenterX, 1, nextCenterZ);
            railTops[i].rotation.y = -currentAngle * 2 * (Math.PI / 180); // Convert degrees to radians for rotation

            // Calculate position and angle updates for the next rail
            // this works for the first rail, but not for subsequent ones after the 90 degree angle goes away...
            //const dx = railWidth / 2 + Math.sin((90 - rightEndAngle * 2) * (Math.PI / 180)) * (railWidth / 2) - Math.cos((90 - rightEndAngle * 2) * (Math.PI / 180)) * (graphicsDepth / 2);
            //const dz = - graphicsDepth / 2 + Math.cos((90 - rightEndAngle * 2) * (Math.PI / 180)) * (railWidth / 2) + Math.sin((90 - rightEndAngle * 2) * (Math.PI / 180)) * (graphicsDepth / 2);
            const dx = Math.cos((90 - rightEndAngle * 2) * (Math.PI / 180)) * (graphicsDepth / 2) + Math.sin((90 - rightEndAngle * 2) * (Math.PI / 180)) * (railWidth / 2) +
                Math.sin((90 - rightEndAngle * 2) * (Math.PI / 180)) * (railWidth / 2) - Math.cos((90 - rightEndAngle * 2) * (Math.PI / 180)) * (graphicsDepth / 2);
            const dz = - Math.sin((90 - rightEndAngle * 2) * (Math.PI / 180)) * (graphicsDepth / 2) + Math.cos((90 - rightEndAngle * 2) * (Math.PI / 180)) * (railWidth / 2) +
                Math.cos((90 - rightEndAngle * 2) * (Math.PI / 180)) * (railWidth / 2) + Math.sin((90 - rightEndAngle * 2) * (Math.PI / 180)) * (graphicsDepth / 2);

            nextCenterX += dx;
            nextCenterZ += dz;
            currentAngle += rightEndAngle;

            console.log(`Rail ${i + 1}: X=${nextCenterX}, Z=${nextCenterZ}, Angle=${currentAngle}°`);
        }

        // Render the scene
        renderer.render(scene, camera);
    }

*/

function createFatSegmentedLineFromInputs(materialColor = 0x00aaff, lineWidth = 25) {
    const numRails = parseInt(document.getElementById("numRails").value);
    const graphicsDepth = parseFloat(document.getElementById("graphicsDepth").value);
    const height = parseFloat(document.getElementById("railHeight").value);

    // Initialize position and angle
    let currentX = 0;
    let currentZ = 0;
    let currentAngle = 0;

    // Create an array for points
    const positions = [];

    for (let i = 0; i < numRails; i++) {
        const railWidth = parseFloat(document.getElementById(`railWidth${i + 1}`).value);
        const rightEndAngle = parseFloat(document.getElementById(`rightEndAngle${i + 1}`).value);
        const graphicsDepth = parseFloat(document.getElementById("graphicsDepth").value);

        // Add the current position
        positions.push(currentX, height, currentZ);

        // Calculate the next position
        const dx = railWidth * Math.cos((currentAngle * Math.PI) / 180);
        const dz = railWidth * Math.sin((currentAngle * Math.PI) / 180);
        currentX += dx;
        currentZ += dz;

        // Update the cumulative angle
        currentAngle += rightEndAngle * 2;
    }

    // Add the final point
    positions.push(currentX, height, currentZ);

    // Create LineGeometry
    const geometry = new LineGeometry();
    geometry.setPositions(positions);

    const colorLibrary = [
        0xff0000, // Red
        0xff00ff, // Magenta
        0x00ffff, // Cyan
        0x00ff00, // Green
        0x0000ff, // Blue
        0x800080, // Purple
        0xffa500, // Orange
    ];

        // Select a color from the color library based on the index
        const color = colorLibrary[numRails % colorLibrary.length];

    // Create LineMaterial
    const material = new LineMaterial({
        color: color,
        linewidth: graphicsDepth, // Set the line width
    });

    // Update projection matrix (needed for LineMaterial)
    material.resolution.set(window.innerWidth, window.innerHeight);

    // remove existing lines
    scene.children.forEach((child) => {
        if (child instanceof Line2) {
            scene.remove(child);
        }
    });

    // Create the Line2 object
    const fatLine = new Line2(geometry, material);
    scene.add(fatLine);
    renderer.render(scene, camera);

    return fatLine;
}

    // document.querySelector('#ridgeline-builder').addEventListener('change', ridgelineBuilder);

    document.querySelector('#ridgeline-builder').addEventListener('change', createFatSegmentedLineFromInputs);

    // on page load run the function
    // ridgelineBuilder();
    createFatSegmentedLineFromInputs();
}