import React from "react";
import { currencyWithoutDecimals } from "../currency";

export default class Mini extends React.Component {
  render() {
    const subtotal = this.props.lineItems.reduce((acc, lineItem) => acc + lineItem.subtotal(), 0);

    return (
      <div>
        {currencyWithoutDecimals(subtotal)}
      </div>
    );
  }
}
